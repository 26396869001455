import { Stack } from '@mui/material';

import {
  SummaryCardNew,
  useConfirmation,
} from '@hapvida/hapvida-core-components';

import { UserRedirectsEnum } from '@services/UserService';

import { useRedirectUser } from '@hooks';

import { ConfirmationText } from '../ConfirmationText';
import { MovementListCard } from '../MovementListCard';
import { MovementsSummaryCard } from '../MovementsSummaryCard';
import type { SearchResultProps } from './types';

export const SearchResult = ({
  movementList,
  reviewsScreenMovement,
}: Readonly<SearchResultProps>) => {
  const { showConfirmation } = useConfirmation();
  const { redirectUser } = useRedirectUser();

  const existsReviewsScreenMovement =
    reviewsScreenMovement?.existsReviewsScreenMovement ?? false;

  const handleRedirectLegacyPortal = () => {
    redirectUser(UserRedirectsEnum.REVIEWS_SCREEN_MOVEMENT);
  };

  const handleCriticismMovement = () => {
    showConfirmation({
      title: 'Críticas de movimentação',
      icon: 'alert',
      type: 'default',
      cancelText: 'Fechar',
      confirmText: 'Acessar portal',
      onConfirm: handleRedirectLegacyPortal,
      content: <ConfirmationText />,
    });
  };

  const criticismButtonProps = existsReviewsScreenMovement
    ? {
        content: 'Críticas de movimentação',
        variant: 'outlined' as 'outlined',
        color: 'secondary' as 'secondary',
        onClick: handleCriticismMovement,
      }
    : undefined;

  return (
    <Stack gap={3} paddingBottom={8}>
      <MovementsSummaryCard />

      <SummaryCardNew.Container>
        <SummaryCardNew.Header
          subtitle="Últimos 60 dias"
          title="Lista de movimentações"
          buttonProps={criticismButtonProps}
        />
        <MovementListCard movementList={movementList} />
      </SummaryCardNew.Container>
    </Stack>
  );
};
