import { Typography } from '@mui/material';
import { memo } from 'react';

export const ConfirmationText = memo(() => (
  <>
    <Typography variant="text" mb={3}>
      Para acessar todas as{' '}
      <Typography variant="text" fontWeight={700}>
        Críticas de Movimentação
      </Typography>
      , clique em “Acessar portal” para ser direcionado para uma nova pagina.{' '}
    </Typography>
    <Typography variant="text">
      Importante: Caso existam, você verá todas as críticas de movimentações por
      tela (digitação) e também via layout.
    </Typography>
  </>
));
